import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const FormControlStyled = styled(FormControl)(({theme}) => ({
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));
// STYLED COMPONENTS BLOCK END

const DropdownFragment = ({
  editData,
  fragmentItem,
  handleChangeDataDropdown,
}) => {
  const [value, setValue] = useState(editData[fragmentItem.id]?.selected);

  const handleChangeSelect = (e) => {
    setValue(e.target.value);
    handleChangeDataDropdown(
      fragmentItem.id,
      e.target.value,
      fragmentItem.values,
    );
  };

  return (
    <>
      {editData && (
        <FormControlStyled fullWidth>
          <InputLabel id={`${fragmentItem.id}-label`}>
            {fragmentItem.name}
          </InputLabel>
          <Select
            labelId={`${fragmentItem.id}-label`}
            id={fragmentItem.id}
            value={value}
            label={fragmentItem.name}
            onChange={handleChangeSelect}
          >
            {fragmentItem?.values?.length &&
              fragmentItem?.values !== [] &&
              fragmentItem.values.map((dropdownItem, dropdownIndex) => {
                return (
                  <MenuItem value={dropdownIndex} key={dropdownItem}>
                    {dropdownItem}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControlStyled>
      )}
    </>
  );
};

DropdownFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataDropdown: PropTypes.func.isRequired,
};

export default DropdownFragment;
