import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardHeader, CardMedia, IconButton} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const ImageCardFragmentWrapper = styled("div")(({theme}) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
}));
// STYLED COMPONENTS BLOCK END

const ImageCardFragment = ({editData, fragmentItem, handleClearDataByFieldId}) => {
  return (
    <ImageCardFragmentWrapper key={fragmentItem.id}>
      <Card style={{width: '300px'}}>
        <CardHeader
          action={
            <IconButton
              aria-label="clear"
              onClick={() => handleClearDataByFieldId(fragmentItem.id)}
            >
              <ClearIcon/>
            </IconButton>
          }
          subheader={fragmentItem.name}
        />
        <CardMedia
          component="img"
          height="194"
          image={editData[fragmentItem.id]}
          alt={fragmentItem.name}
        />
      </Card>
    </ImageCardFragmentWrapper>
  );
};

ImageCardFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleClearDataByFieldId: PropTypes.func.isRequired,
};

export default ImageCardFragment;
