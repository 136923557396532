import React, { createContext, useContext, useReducer } from "react";
import { getConfig } from "../config";

const reducerInitialState = {
  data: {},
  isFetching: false,
  hasError: false,
  loadComplete: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_INIT":
      return {
        ...state,
        isFetching: true,
        hasError: false,
        loadComplete: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        loadComplete: true,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const AdminContext = createContext();

export const AdminProvider = ({ children }) => {
  const [admin, dispatchAdmin] = useReducer(reducer, reducerInitialState);
  const [contentLib, dispatchContentLib] = useReducer(reducer, reducerInitialState);
  const [media, dispatchMedia] = useReducer(reducer, reducerInitialState);
  const { apiRoot } = getConfig();

  const getAdmin = async (token) => {
    dispatchAdmin({
      type: "FETCH_INIT",
    });
    fetch(`${apiRoot}/v1/admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatchAdmin({
          type: "FETCH_SUCCESS",
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchAdmin({
          type: "FETCH_FAILURE",
        });
      });
  };

  const getContentLib = async (token, contentId) => {
    dispatchContentLib({
      type: "FETCH_INIT",
    });
    fetch(`${apiRoot}/v1/${contentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatchContentLib({
          type: "FETCH_SUCCESS",
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchContentLib({
          type: "FETCH_FAILURE",
        });
      });
  };
  const getMedia = async (token) => {
    dispatchMedia({
      type: "FETCH_INIT",
    });
    fetch(`${apiRoot}/v1/media`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatchMedia({
          type: "FETCH_SUCCESS",
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchMedia({
          type: "FETCH_FAILURE",
        });
      });
  };
  return (
    <AdminContext.Provider
      value={{
        admin,
        getAdmin,
        contentLib,
        getContentLib,
        media,
        getMedia,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => useContext(AdminContext);
