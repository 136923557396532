import React from 'react';
import PropTypes from 'prop-types';
import UniversalButton from "../../components/Button";

const Toolbox = ({ handleCreateItem }) => {
  return (
    <div>
      <UniversalButton onClick={handleCreateItem} color="primary" variant="contained">
        Nová položka
      </UniversalButton>
    </div>
  );
};

Toolbox.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.object.isRequired,
  handleCreateItem: PropTypes.func.isRequired,
};

export default Toolbox;
