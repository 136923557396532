import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle, styled, Tab, Tabs, TextField} from "@material-ui/core";
import {Button, Card, CardHeader, CardMedia, IconButton, ImageList, ImageListItem} from "@mui/material";
import ClearIcon from "@material-ui/icons/Clear";
import {useAuth} from "../../../security/auth_context";
import {useAdminContext} from "../../../context/context";
import {getConfig} from "../../../config";
import {uploadMedia} from "../../../helpers/uploadMedia";
import UniversalButton from "../../../components/Button";

// STYLED COMPONENTS BLOCK START
const GalleryWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  alignItems: "center",
  "& div.gallery-preview": {
    display: "flex",
    flexDirection: "row",
    flexWrap:"wrap",
    margin: theme.spacing(3)
  },
  "& p": {
    margin: 0,
  },
  "& .upload-field": {
    display: "flex",
    justifyContent:"center",
  }
}));

const DialogStyled = styled(Dialog)(({theme}) => ({
  "& .upload-field": {
    display: "flex",
    justifyContent:"center",
    padding: theme.spacing(3),
  }
}));
// STYLED COMPONENTS BLOCK END

const GalleryFragment = ({editData, fragmentItem, handleChangeDataRemoveFromArray, handleChangeDataAppendToArray}) => {
  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  const {media} = useAdminContext()
  const {apiRoot} = getConfig();

  const [dialogTab, setDialogTab] = useState("upload")
  const [imagePickerDialogOpen, setImagePickerDialogOpen] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState()

  //START DIALOG STATE HANDLERS
  const handleOpenImagePickerDialog = () => {
    setDialogTab("upload")
    setImagePickerDialogOpen(true)
  }

  const handleCloseImagePickerDialog = () => {
    setImagePickerDialogOpen(false)
    setSelectedImageUrl(undefined)
  }

  const handleSaveAndCloseImagePickerDialog = () => {
    handleChangeDataAppendToArray(fragmentItem.id, selectedImageUrl);
    setImagePickerDialogOpen(false)
    setSelectedImageUrl(undefined)
  }

  const handleClearSelectedImage = () => {
    setSelectedImageUrl("")
  }

  const handleChangeImageUrl = (event) => {
    setSelectedImageUrl(event.target.value)
  }

  const handleSelectFromMediaLibrary = (url) => {
    setSelectedImageUrl(url)
  }
  //END DIALOG STATE HANDLERS

  // START DIALOG TABS HANDLERS
  const handleDialogTabChange = (event, newValue) => {
    setDialogTab(newValue);
  };
  // END DIALOG TABS HANDLERS

  //START DIALOG DATA HANDLERS
  const handleUploadImageAndSetSelectedImage = async (event) => {
    const responseJSON = await uploadMedia(event, apiRoot, authentication, getTokenSilently, isAuthenticated)
    setSelectedImageUrl(responseJSON.Src)
  }
  //END DIALOG DATA HANDLERS

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <GalleryWrapper key={fragmentItem.id}>
      <p>{fragmentItem.name}: </p>
      <div className={"gallery-preview"}>
      {editData[fragmentItem.id] &&
      editData[fragmentItem.id] !== [] &&
      editData[fragmentItem.id].map((galItem) => (
        <Card style={{width: '300px'}}>
          <CardHeader
            action={
              <IconButton
                aria-label="clear"
                onClick={() =>
                  handleChangeDataRemoveFromArray(
                    fragmentItem.id,
                    galItem,
                  )
                }
              >
                <ClearIcon/>
              </IconButton>
            }
          />
          <CardMedia
            component="img"
            height="194"
            image={galItem}
            alt={galItem}
          />
        </Card>
      ))}
      </div>
      <UniversalButton
        variant="contained"
        component="span"
        color="secondary"
        onClick={handleOpenImagePickerDialog}
      >
        Přidat obrázek
      </UniversalButton>

      <DialogStyled open={imagePickerDialogOpen} onClose={handleCloseImagePickerDialog}
              aria-labelledby="image-picker-dialog-title">
        <DialogTitle id="image-picker-dialog-title">Přidání obrázku</DialogTitle>
        <DialogContent>
          <Tabs value={dialogTab} onChange={handleDialogTabChange} centered>
            <Tab label="Nahrát" value="upload"/>
            <Tab label="Přidat z URL" value="url"/>
            <Tab label="Přidat z knihovny" value="library"/>
          </Tabs>
          {dialogTab === "upload" && (
            (!!selectedImageUrl && selectedImageUrl !== "")
              ? (<div className="upload-field">
                <Card style={{width: "300px"}}>
                  <CardHeader
                    action={
                      <IconButton aria-label="clear"
                                  onClick={handleClearSelectedImage}>
                        <ClearIcon/>
                      </IconButton>
                    }
                  />
                  <CardMedia
                    component="img"
                    image={selectedImageUrl}
                    alt={fragmentItem.id}
                  />
                </Card>
              </div>)
              :
              (<div className="upload-field">
                <label htmlFor="uploader">
                  <Input
                    accept="image/*"
                    id="uploader"
                    type="file"
                    onChange={handleUploadImageAndSetSelectedImage}
                  />
                  <UniversalButton variant="contained" component="span">
                    Vybrat
                  </UniversalButton>
                </label>
              </div>)
          )
          }
          {dialogTab === "url" && (
            <TextField
              autoFocus
              margin="dense"
              label="URL souboru"
              value={selectedImageUrl}
              fullWidth
              onChange={handleChangeImageUrl} multiline/>
          )
          }
          {dialogTab === "library" && (
            <ImageList cols={3} rowHeight={164} variant="masonry" gap={10}>
              {media?.data?.filter(item => item.type === "image").map((item) => (
                <ImageListItem key={item.id} onClick={() => handleSelectFromMediaLibrary(item.src)}>
                  <img
                    src={`${item.src}?w=164&fit=crop&auto=format`}
                    srcSet={`${item.src}?w=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.desc}
                    loading="lazy"
                    style={(selectedImageUrl === item.src) ? {border: "5px solid gold"} : {}}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImagePickerDialog} color="primary">
            Zrušit
          </Button>
          <Button onClick={handleSaveAndCloseImagePickerDialog} color="primary">
            Přidat obrázek
          </Button>
        </DialogActions>
      </DialogStyled>
    </GalleryWrapper>
  );
};

GalleryFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataAppendToArray: PropTypes.func.isRequired,
};

export default GalleryFragment;
