import React from 'react';
import PropTypes from 'prop-types';
import UniversalButton from "../../components/Button";

const Toolbox = ({ handleUploadMediaOpen }) => {
  return (
    <div>
      <UniversalButton
        onClick={() => handleUploadMediaOpen()}
        color="primary"
        variant="contained"
      >
        Nahrát média
      </UniversalButton>
    </div>
  );
};

Toolbox.propTypes = {
  handleUploadMediaOpen: PropTypes.func.isRequired,
};

export default Toolbox;
