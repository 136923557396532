import React from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/material/styles";
import Button from '@mui/material/Button';

const UniversalButton = ({color = "primary", component, variant, onClick, children, type}) => {
  // STYLED COMPONENTS BLOCK START
  const StyledButton = styled(Button)(({theme}) => ({
    "&.MuiButton-containedPrimary": {
      color: theme.palette.button.primary.text,
      backgroundColor: theme.palette.button.primary.background,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      borderRadius: 4,
      whiteSpace: "no-wrap",
      '&:hover': {
        backgroundColor: theme.palette.button.primary.hoverBackground,
      },
    },
    "&.MuiButton-containedSecondary": {
      color: theme.palette.button.secondary.text,
      backgroundColor: theme.palette.button.secondary.background,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      borderRadius: 4,
      whiteSpace: "no-wrap",
      '&:hover': {
        backgroundColor: theme.palette.button.secondary.hoverBackground,
      },
    }
  }))
  // STYLED COMPONENTS BLOCK END


  return (
    <StyledButton {...{color, component, onClick, type, variant}} >{children}</StyledButton>
  );
};

UniversalButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default UniversalButton;
