import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from "@material-ui/core";

const TextareaFragment = ({editData, fragmentItem, handleChangeData}) => {
  return (
    <TextField
      key={fragmentItem.id}
      margin="dense"
      id={fragmentItem.id}
      label={fragmentItem.name}
      value={editData[fragmentItem.id]}
      multiline
      fullWidth
      required={!!fragmentItem.isRequired}
      onChange={handleChangeData}
    />
  );
};

TextareaFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeData: PropTypes.func.isRequired,
};

export default TextareaFragment;
