import PropTypes from 'prop-types';
import {useEffect} from "react";
import {useAuth} from "../../security/auth_context";
import {useAdminContext} from "../../context/context";
import ContentTable from "./ContentTable";
import Toolbox from "./Toolbox";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const ContentLibraryWrapper = styled("div")(({theme}) => ({
  display: "flex",
  width: "100%",
  borderRight: `2px solid ${theme.palette.tertiary.main}`,
  // overflow: "hidden",
}));

const ContentLibraryMain = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1100px",
  margin: `${theme.spacing(5)} ${theme.spacing(9)}`,
}));

const ContentLibraryToolbar = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: `0 0 ${theme.spacing(3)} 0`,
}));

const ContentLibraryToolbarTools = styled("div")(({theme}) => ({
  alignSelf: "flex-start",
  display: "flex",
}));

const ContentLibraryList = styled("div")(({theme}) => ({
  maxWidth: "100%",
}));
// STYLED COMPONENTS BLOCK END


const ContentLibrary = ({contentId}) => {
  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  const {admin, contentLib, getContentLib, getMedia} = useAdminContext()
  const navigate = useNavigate();

  const getData = async () => {
    try {
      await getTokenSilently();
      if (isAuthenticated) {
        await getContentLib(authentication.token, contentId);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getMediaData = async () => {
    try {
      await getTokenSilently();
      if (isAuthenticated) {
        await getMedia(authentication.token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [contentId]);

  useEffect(() => {
    getMediaData()
    // eslint-disable-next-line
  }, [])

  const handleCreateItem = () => {
    navigate(`/admin/${contentId}/createitem`)
  }

  return (
    <ContentLibraryWrapper>
      <ContentLibraryMain>
        <ContentLibraryToolbar>
          <h2>
            {admin.data.length &&
              admin.data.find((item) => item.name === contentId).displayName}
          </h2>
          <ContentLibraryToolbarTools>
            {admin.data.length && (
              <Toolbox
                contentType={admin.data.find((item) => item.name === contentId)}
                {...{
                  contentId,
                  handleCreateItem,
                }}
              />
            )}
          </ContentLibraryToolbarTools>
        </ContentLibraryToolbar>
        <ContentLibraryList>
          {admin.data.length && contentLib.data?.length && (
            <ContentTable
              contentType={admin.data.find((item) => item.name === contentId)}
              {...{
                contentId,
                contentLib,
                getData,
                handleCreateItem,
              }}
            />
          )}
        </ContentLibraryList>
      </ContentLibraryMain>
    </ContentLibraryWrapper>
  );
};

ContentLibrary.propTypes = {
  contentId: PropTypes.string.isRequired,
};

export default ContentLibrary;
