import React, { createContext, useContext, useReducer } from "react";
import { userService } from "./sec_services";
import jwt_decode from "jwt-decode";

const reducerInitialState = {
  token: {},
  isAuthenticated: false,
  loading: false,
  hasError: false,
  expiration: null,
  error: null,
  userName: null,
  customerId: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH_INIT":
      return {
        ...state,
        loading: true,
        hasError: false,
        error: null
      };
    case "AUTH_SUCCESS":
      return {
        ...state,
        token: action.payload,
        expiration: action.expiration,
        userName: action.userName,
        customerId: action.customerId,
        isAuthenticated: true,
        loading: false,
      };
    case "AUTH_FAILURE":
      return {
        ...state,
        hasError: true,
        isAuthenticated: false,
        loading: false,
        error: action.error,
      };
    case "AUTH_LOGOUT":
      return {...state,
        token: {},
        isAuthenticated: false,
        userName: null,
        customerId: null,
        loading: false,
        hasError: false,
        expiration: null}
    default:
      return state;
  }
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authentication, dispatch] = useReducer(reducer, reducerInitialState);

  const getToken = async (name, pass) => {
    dispatch({
      type: "AUTH_INIT",
    });
    try {
      const token = JSON.parse(await userService.login(name, pass));
      if (token) {
        dispatch({
          type: "AUTH_SUCCESS",
          payload: token.token,
          expiration: jwt_decode(token.token).exp,
          userName: token.userName,
          customerId: token.customerId
        });
      }
    } catch {
      dispatch({
        type: "AUTH_FAILURE",
        error: "Chyba přihlášení! Zkontrolujte údaje a zkuste to znovu."
      })
    }

    // Add error handeling
  };

  const getTokenSilently = async () => {
    if (authentication.isAuthenticated && ((new Date((authentication.expiration - (60*3)) * 1000)) > new Date())) {
      return
    } else if (!authentication.hasError) {
      try {
        dispatch({
          type: "AUTH_INIT",
        });
        const token = JSON.parse(await userService.session())
        dispatch({
            type: "AUTH_SUCCESS",
            payload: token.token,
            expiration: jwt_decode(token.token).exp,
          userName: token.userName,
          customerId: token.customerId
          });
      } catch {
        dispatch({type: "AUTH_FAILURE"})
      }
    return
    }
  };

  const logout = async () => {
    await userService.logout()
    document.cookie = "session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    await dispatch({
      type: "AUTH_LOGOUT"
    })
    console.log("auth logout", authentication)
  }

  return (
    <AuthContext.Provider
      value={{
        authentication,
        error: authentication.error,
        hasError: authentication.hasError,
        isAuthenticated: authentication.isAuthenticated,
        loading: authentication.loading,
        getToken,
        getTokenSilently,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
