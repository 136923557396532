export const CONTENT_FIELD_TYPE_CHECKBOX_WITH_TWO_TEXT_FIELDS = "checkboxWithTwoTextFields"
export const CONTENT_FIELD_TYPE_DATETIME = "datetime"
export const CONTENT_FIELD_TYPE_DROPDOWN = "dropdown"
export const CONTENT_FIELD_TYPE_FULLTEXT_EDITOR = "richtextEditor"
export const CONTENT_FIELD_TYPE_GALLERY = "gallery"
export const CONTENT_FIELD_TYPE_IMAGE = "image"
export const CONTENT_FIELD_TYPE_LINK = "link"
export const CONTENT_FIELD_TYPE_MEDIA = "media"
export const CONTENT_FIELD_TYPE_TEXTAREA = "textarea"
export const CONTENT_FIELD_TYPE_TEXTFIELD = "textfield"
export const CONTENT_FIELD_TYPE_TOGGLE = "toggle"
