import React from 'react';
import PropTypes from 'prop-types';
// import {FormControlLabel, Switch, SwitchUnstyled} from "@mui/material";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const SwitchWrapper = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
}));

const Switch = styled("label")(({theme}) => ({
  position: "relative",
  display: "inline-block",
  width: "60px",
  height: "34px",
  margin: theme.spacing(3),
  "& input": {
    opacity: 0,
    width: 0,
    height: 0,
    "&:checked + .slider": {
      backgroundColor: theme.palette.button.primary.background,
    },
    "&:focus + .slider": {
      boxShadow: "0 0 1px #2196F3",
    },
    "&:checked + .slider:before": {
      transform: "translateX(26px)",
    }
  },
  "& .slider": {
    borderRadius: "34px",
    position: "absolute",
    cursor: "pointer",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#ccc",
    transition: ".4s",
    "&:before": {
      position: "absolute",
      content: `""`,
      height: "26px",
      width: "26px",
      left: "4px",
      bottom: "4px",
      backgroundColor: "white",
      transition: ".4s",
      borderRadius: "50%",
      zIndex: 1000,
    }
  }
}));
// STYLED COMPONENTS BLOCK END

const ToggleFragment = ({editData, fragmentItem, handleChangeDataSwitchChecked}) => {
  return (
    <SwitchWrapper>
      <Switch className="switch">
        <input type="checkbox" checked={editData[fragmentItem.id]} onChange={() => handleChangeDataSwitchChecked({
          target: {
            id: fragmentItem.id,
            checked: !editData[fragmentItem.id]
          }
        })}/>
        <span className="slider"/>
      </Switch>
      {fragmentItem.name}
    </SwitchWrapper>
  );
};

ToggleFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataSwitchChecked: PropTypes.func.isRequired,
};

export default ToggleFragment;
