import React from 'react';
import {styled} from "@mui/system";

// STYLED COMPONENTS BLOCK START
const FooterBlock = styled("footer")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  justifyContent: "space-around",
  alignItems: "center",
  background: theme.palette.grey[700],
  color: theme.palette.secondary.contrastText,
  padding: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
    flexDirection: "row",
  },
  "& h4, & p, & a": {
    margin: theme.spacing(3),
  },
  "& a": {
    color: theme.palette.secondary.contrastText,
    textDecoration: "none",
  }
}));
// STYLED COMPONENTS BLOCK END

const Footer = () => (
  <FooterBlock>
    <h4>SpaceBarKeepers</h4>
    <a href="mailto:info@spacebarkeepers.com">info@spacebarkeepers.com</a>
    <a href="https://spacebarkeepers.com">spacebarkeepers.com</a>
  </FooterBlock>
);

export default Footer;
