import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const CheckBoxWithTwoTextFieldsFragmentWrapper = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "flex-end",
  padding: theme.spacing(3),
}));

const TextFieldStyled = styled(TextField)(({theme}) => ({
  '& > :not(style)': { marginLeft: 10 },
}));
// STYLED COMPONENTS BLOCK END

const CheckBoxWithTwoTextFieldsFragment = ({editData, fragmentItem, handleChangeDataArrayItemChange}) => {
  return (
    <CheckBoxWithTwoTextFieldsFragmentWrapper key={fragmentItem.id}>
      <FormControlLabel
        control={
          <Checkbox
            id={fragmentItem.id + 0}
            checked={
              editData[fragmentItem.id] ? editData[fragmentItem.id][0] : false
            }
            onChange={(e) =>
              handleChangeDataArrayItemChange(
                fragmentItem.id,
                0,
                e.target.checked,
              )
            }
          />
        }
        label={fragmentItem.name[0]}
      />
      <TextFieldStyled
        size="small"
        variant="standard"
        id={fragmentItem.id + 1}
        label={fragmentItem.name[1]}
        value={editData[fragmentItem.id] ? editData[fragmentItem.id][1] : ''}
        onChange={(e) =>
          handleChangeDataArrayItemChange(
            fragmentItem.id,
            1,
            e.target.value,
          )
        }
      />
      <TextFieldStyled
        size="small"
        variant="standard"
        id={fragmentItem.id + 2}
        label={fragmentItem.name[2]}
        value={editData[fragmentItem.id] ? editData[fragmentItem.id][2] : ''}
        onChange={(e) =>
          handleChangeDataArrayItemChange(
            fragmentItem.id,
            2,
            e.target.value,
          )
        }
      />
    </CheckBoxWithTwoTextFieldsFragmentWrapper>
  );
};

CheckBoxWithTwoTextFieldsFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataArrayItemChange: PropTypes.func.isRequired,
};

export default CheckBoxWithTwoTextFieldsFragment;
