import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {v4 as uuidv4} from 'uuid';
import {useAuth} from '../../security/auth_context';
import {getConfig} from '../../config';
import documentImg from '../../images/document.png';
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const TableContainerStyled = styled(TableContainer)(({theme}) => ({
  "& .media-table": {
    width: "100%",
  },
  "& .preview": {
    maxWidth: "350px",
  },
  "& td": {
    textAlign: "center",
  },
  "& .table": {
    minWidth: "650px",
  },
  "& .tableRow": {
    "& img": {
      maxHeight: "150px",
    },
  },
}));

// STYLED COMPONENTS BLOCK END

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MediaTable = ({media, getData, handleUploadMediaOpen}) => {
  const [rows, setRows] = useState([]);
  const [deleteItemOpen, setDeleteItemOpen] = useState(false);
  const [deleteSnackSuccess, setDeleteSnackSuccess] = useState(false);
  const [deleteSnackFailure, setDeleteSnackFailure] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  const {apiRoot} = getConfig();

  const columns = [
    {columnName: 'Popisek', columnId: 'desc'},
    {columnName: 'URL', columnId: 'src'},
  ];

  useEffect(() => {
    media.data.length && setRows([...media.data]);
  }, [media]);

  const handleDeleteMedia = async () => {
    setDeleteItemOpen(false);

    try {
      await getTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (isAuthenticated) {
      const response = await fetch(`${apiRoot}/v1/media`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authentication.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          itemId: selectedItem.itemId,
          itemName: selectedItem.itemName,
        }),
      });
      const fetchResponse = await response.json();

      if (fetchResponse.ResponseCode === 1) {
        setDeleteSnackSuccess(true);
      } else {
        setDeleteSnackFailure(true);
      }
    }

    getData();
  };

  const handleDeleteMediaOpen = (id, name) => {
    setDeleteItemOpen(true);
    setSelectedItem({itemId: id, itemName: name});
  };

  const handleDeleteMediaClose = () => {
    setDeleteItemOpen(false);
  };

  const handleDeleteMediaSnackSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleteSnackSuccess(false);
  };

  const handleDeleteMediaSnackFailureClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleteSnackFailure(false);
  };

  return (
    <>
      <TableContainerStyled component={Paper}>
        <Table className="table" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{fontWeight: '700'}} align="center">
                Náhled
              </TableCell>
              {columns.map((item) => (
                <TableCell
                  key={uuidv4()}
                  align="center"
                  style={{fontWeight: '700'}}
                >
                  {item.columnName}
                </TableCell>
              ))}
              <TableCell style={{fontWeight: '700'}} align="center">
                Úpravy
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} className="tableRow">
                <TableCell align="center">
                  <img
                    src={row.type === 'image' ? row.src : documentImg}
                    alt={row.desc}
                    className="preview"
                    style={{maxWidth: '100px'}}
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={uuidv4()} style={{maxWidth: "40vw", wordWrap: "break-word"}} align="center">
                    {row[column.columnId]}
                  </TableCell>
                ))}
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => handleUploadMediaOpen(row.id)}
                  >
                    <EditIcon/>
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteMediaOpen(row.id, row.name)}
                  >
                    <DeleteForeverIcon color="error"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>

      <Dialog
        open={deleteItemOpen}
        onClose={handleDeleteMediaClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Smazat položku</DialogTitle>
        <DialogContent>
          Chystáte se smazat jednu z položek. Opravdu chcete pokračovat ve
          smazání?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteMediaClose}
            color="grey"
            variant="contained"
          >
            Zpět
          </Button>
          <Button
            onClick={() => handleDeleteMedia(selectedItem)}
            color="secondary"
            variant="contained"
          >
            Smazat položku
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSnackSuccess}
        autoHideDuration={6000}
        onClose={handleDeleteMediaSnackSuccessClose}
      >
        <Alert severity="success" onClose={handleDeleteMediaSnackSuccessClose}>
          Položka úspěšně smazána!
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteSnackFailure}
        autoHideDuration={6000}
        onClose={handleDeleteMediaSnackFailureClose}
      >
        <Alert severity="error" onClose={handleDeleteMediaSnackFailureClose}>
          Při mazání položky došlo k chybě!
        </Alert>
      </Snackbar>
    </>
  );
};

MediaTable.propTypes = {
  media: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
  handleUploadMediaOpen: PropTypes.func.isRequired,
};

export default MediaTable;
