import React from 'react';
import PropTypes from 'prop-types';
import UniversalButton from "../../components/Button";

const ToolboxContentEditor = ({handleGoBackWithoutSaving, handleSave}) => {
  return (
    <>
      <UniversalButton onClick={handleGoBackWithoutSaving} color="primary" variant="contained">
        Zrušit změny
      </UniversalButton>
      <UniversalButton onClick={handleSave} color="primary" variant="contained">
        Uložit změny
      </UniversalButton>
    </>
  );
};

ToolboxContentEditor.propTypes = {
  handleGoBackWithoutSaving: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default ToolboxContentEditor;
