// import PropTypes from 'prop-types'
import {styled} from "@mui/system";

// STYLED COMPONENTS BLOCK START
const DashboardWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  backgroundColor: theme.palette.background.default,
  borderRight: `2px solid ${theme.palette.tertiary.main}`,
  "& h1": {
    textAlign: "center",
    margin: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(1313)
    },
  }
}));
// STYLED COMPONENTS BLOCK END

const Dashboard = () => {
  return (
    <DashboardWrapper>
      <h1>Vítejte v administraci Vaší webové stránky</h1>
    </DashboardWrapper>
  );
};

Dashboard.propTypes = {};

export default Dashboard;
