import React from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/styles";
import UserSettingsChangePassword from "./UserSettingsChangePassword";

const UserSettingsContainer = styled("div")(({theme}) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: 5,
  margin: 10,
  padding: 10,
  "& h3": {
    textAlign: "center",
    marginTop: 0,
  },
}));

const UserSettings = ({setSnackbarOpen, setSnackbarMessage, setSnackbarVariant}) => {
  return (
    <UserSettingsContainer>
      <h3>
        Nastavení uživatele
      </h3>
      <UserSettingsChangePassword {...{setSnackbarOpen, setSnackbarMessage, setSnackbarVariant}} />
    </UserSettingsContainer>
  );
};

UserSettings.propTypes = {
  setSnackbarOpen: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
  setSnackbarVariant: PropTypes.func.isRequired,
};

export default UserSettings;
