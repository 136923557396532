import bcrypt from "bcryptjs-cfw"

export const changePassword = async (oldPassword, newPassword, apiRoot, authentication, getTokenSilently, isAuthenticated) => {
  const newHash = await bcrypt.hashSync(newPassword, 12)

  try {
    await getTokenSilently();
  } catch (error) {
    console.error(error);
  }
  if (isAuthenticated) {
    const response = await fetch(`${apiRoot}/v1/auth/changeOwnPassword`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${await authentication.token}`,
      },
      body: JSON.stringify({oldPassword, newHash})
    });
    return await response.json()
  }
}
