import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {
  Card,
  CardHeader,
  CardMedia, Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, styled,
  Tab,
  Tabs,
  TextField
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {useAuth} from "../../../security/auth_context";
import {uploadMedia} from "../../../helpers/uploadMedia";
import {getConfig} from "../../../config";
import documentImg from "../../../images/document.png";
import UniversalButton from "../../../components/Button";

// STYLED COMPONENTS BLOCK START
const MediaPickerWrapper = styled("div")(({theme}) => ({
  display: "flex",
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  alignItems: "center",
  "& p": {
    margin: 0,
  },
}));

const DialogStyled = styled(Dialog)(({theme}) => ({
  "& .upload-field": {
    display: "flex",
    justifyContent:"center",
    padding: theme.spacing(3),
  }
}));
// STYLED COMPONENTS BLOCK END

const MediaPickerFragment = ({editData, fragmentItem, handleChangeDataByFieldId}) => {
  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  // const {media, getMedia} = useAdminContext()
  const {apiRoot} = getConfig();

  const [dialogTab, setDialogTab] = useState("upload")
  const [imagePickerDialogOpen, setImagePickerDialogOpen] = useState(false)
  const [selectedMediaUrl, setSelectedMediaUrl] = useState()
  const [selectedMediaType, setSelectedMediaType] = useState(undefined)

  useEffect(() => {
    if (!!editData[fragmentItem.id] && editData[fragmentItem.id] !== "") setSelectedMediaUrl(editData[fragmentItem.id])
    // eslint-disable-next-line
  }, [editData])

  //START DIALOG STATE HANDLERS
  const handleOpenMediaPickerDialog = () => {
    setImagePickerDialogOpen(true)
  }

  const handleCloseMediaPickerDialog = () => {
    setImagePickerDialogOpen(false)
    setSelectedMediaUrl(undefined)
  }

  const handleSaveAndCloseImagePickerDialog = () => {
    handleChangeDataByFieldId(selectedMediaUrl, fragmentItem.id)
    setImagePickerDialogOpen(false)
  }

  const handleClearSelectedImage = () => {
    setSelectedMediaUrl("")
  }

  const handleChangeMediaUrl = (event) => {
    setSelectedMediaUrl(event.target.value)
  }
  //END DIALOG STATE HANDLERS

  // START DIALOG TABS HANDLERS
  const handleDialogTabChange = (event, newValue) => {
    setDialogTab(newValue);
  };
  // END DIALOG TABS HANDLERS

  //START DIALOG DATA HANDLERS
  const handleUploadMediaAndSetSelectedMedia = async (event) => {
    const responseJSON = await uploadMedia(event, apiRoot, authentication, getTokenSilently, isAuthenticated)
    setSelectedMediaUrl(responseJSON.Src)
    setSelectedMediaType(responseJSON.Type)
  }
  //END DIALOG DATA HANDLERS

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <MediaPickerWrapper key={fragmentItem.name}>
      <p>{fragmentItem.name}: </p>
      {selectedMediaUrl && selectedMediaUrl !== "" ?
      (
        <Card style={{width: "300px"}}>
          <CardHeader
            action={
              <IconButton aria-label="clear"
                          onClick={handleClearSelectedImage}>
                <ClearIcon/>
              </IconButton>
            }
          />
          <CardMedia
            component="img"
            image={selectedMediaType === "image" ? selectedMediaUrl : documentImg}
            alt={fragmentItem.id}
          />
        </Card>
      ) : (
        <UniversalButton
        variant="contained"
        color="secondary"
        component="span"
        onClick={handleOpenMediaPickerDialog}
        >
        Přidat médium
        </UniversalButton>
        )}

      <DialogStyled open={imagePickerDialogOpen} onClose={handleCloseMediaPickerDialog}
              aria-labelledby="image-picker-dialog-title">
        <DialogTitle id="image-picker-dialog-title">Přidání obrázku</DialogTitle>
        <DialogContent>
          <Tabs value={dialogTab} onChange={handleDialogTabChange} centered>
            <Tab label="Nahrát" value="upload"/>
            <Tab label="Přidat z URL" value="url"/>
          </Tabs>
          {dialogTab === "upload" && (
            (!!selectedMediaUrl && selectedMediaUrl !== "")
              ? (
                <Card style={{width: "300px"}}>
                  <CardHeader
                    action={
                      <IconButton aria-label="clear"
                                  onClick={handleClearSelectedImage}>
                        <ClearIcon/>
                      </IconButton>
                    }
                  />
                  <CardMedia
                    component="img"
                    image={selectedMediaType === "image" ? selectedMediaUrl : documentImg}
                    alt={fragmentItem.id}
                  />
                </Card>
              )
              :
              (<div className="upload-field">
                <label htmlFor="uploader">
                  <Input
                    accept="image/*,.docx,.pdf,.pptx,.txt"
                    id="uploader"
                    type="file"
                    onChange={handleUploadMediaAndSetSelectedMedia}
                  />
                  <UniversalButton variant="contained" component="span">
                    Vybrat
                  </UniversalButton>
                </label>
              </div>)
          )
          }
          {dialogTab === "url" && (
            <TextField
              autoFocus
              margin="dense"
              label="URL souboru"
              value={selectedMediaUrl}
              fullWidth
              onChange={handleChangeMediaUrl}
                multiline/>
          )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMediaPickerDialog} color="primary">
            Zrušit
          </Button>
          <Button onClick={handleSaveAndCloseImagePickerDialog} color="primary">
            Přidat médium
          </Button>
        </DialogActions>
      </DialogStyled>
    </MediaPickerWrapper>


  );
};

MediaPickerFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataByFieldId: PropTypes.func.isRequired,
};

export default MediaPickerFragment;
