import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import {useAuth} from '../../security/auth_context';
import {useAdminContext} from '../../context/context';
import ToolboxMedia from './ToolboxMedia';
import MediaTable from './MediaTable';
import {getConfig} from '../../config';
import MediaForm from './MediaForm';
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const MediaLibraryWrapper = styled("div")(({theme}) => ({
  display: "flex",
  width: "100%",
  borderRight: `2px solid ${theme.palette.tertiary.main}`,
  // overflow: "hidden"
}));

const MediaLibraryMain = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1100px",
  margin: `${theme.spacing(5)} ${theme.spacing(9)}`,
}));

const MediaLibraryToolbar = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: `0 0 ${theme.spacing(3)} 0`,
}));

const MediaLibraryToolbarTools = styled("div")(({theme}) => ({
  alignSelf: "flex-start",
  display: "flex",
}));

const MediaLibraryList = styled("div")(({theme}) => ({
  maxWidth: "100%",
}));
// STYLED COMPONENTS BLOCK END

const MediaLibrary = () => {
  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  const {media, getMedia} = useAdminContext();
  const {apiRoot} = getConfig();
  const [uploadMediaOpen, setUploadMediaOpen] = useState(false);
  const [editMediaOpen, setEditMediaOpen] = useState(false);
  const [mediaData, setMediaData] = useState({});
  const [noFileSnackOpen, setNoFileSnackOpen] = useState(false);

  const getData = async () => {
    try {
      await getTokenSilently();
      if (isAuthenticated) {
        await getMedia(authentication.token);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const handleUploadMediaOpen = (itemId = undefined) => {
    // e.preventDefault()
    setMediaData({});
    console.log('itemId', itemId);
    if (itemId === undefined) {
      setUploadMediaOpen(true);
    } else {
      setMediaData({...media.data?.find((item) => item.id === itemId)});
      setEditMediaOpen(true);
    }
  };

  const handleUploadMediaClose = () => {
    setUploadMediaOpen(false);
    !!mediaData.id &&
    handleMediaUploadDialogClearFile(mediaData.id, mediaData.name);
  };

  const handleUploadMediaChange = (event) => {
    setMediaData({...mediaData, [event.target.id]: event.target.value});
  };

  const handleMediaUploadDialogClearFile = async (id, name) => {
    try {
      await getTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (isAuthenticated) {
      const response = await fetch(`${apiRoot}/v1/media`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authentication.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({itemID: id, itemName: name}),
      });
      const fetchReponse = await response.json();
      console.log(fetchReponse);
    }

    setMediaData({});
  };

  const handleMediaEditDialogClearFile = async (id, name) => {
    try {
      await getTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (isAuthenticated) {
      const response = await fetch(`${apiRoot}/v1/media`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authentication.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({itemID: id, itemName: name}),
      });
      const fetchReponse = await response.json();
      console.log(fetchReponse);
    }

    setMediaData({
      ...mediaData,
      id: undefined,
      name: undefined,
      src: undefined,
    });
  };

  const handleUploadMediaCloseAndSave = async () => {
    if (Object.keys(mediaData).length === 0) {
      handleNoFileSnackOpen();
      return;
    }
    try {
      await getTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (isAuthenticated) {
      const response = await fetch(`${apiRoot}/v1/media`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authentication.token}`,
        },
        body: JSON.stringify(mediaData),
      });
      const responseJSON = await response.json();
      console.log(responseJSON);
    }
    setUploadMediaOpen(false);
    setEditMediaOpen(false);
    await getData();
  };

  const handleEditMediaClose = () => {
    setEditMediaOpen(false);
  };

  const handleNoFileSnackOpen = () => {
    setNoFileSnackOpen(true);
  };

  const handleNoFileSnackClose = () => {
    setNoFileSnackOpen(false);
  };

  const handleMediaUploadFile = async (event) => {
    console.log(event);

    let formData = new FormData();
    formData.append('uploadFile', event.target.files[0]);

    try {
      await getTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (isAuthenticated) {
      const response = await fetch(`${apiRoot}/v1/media`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authentication.token}`,
        },
        body: formData,
      });
      const responseJSON = await response.json();
      setMediaData({
        ...mediaData,
        name: responseJSON.Name,
        src: responseJSON.Src,
        id: responseJSON.Id,
        type: responseJSON.Type,
      });
    }
  };

  useEffect(() => {
    console.log(mediaData);
  }, [mediaData]);

  return (
    <MediaLibraryWrapper>
      <MediaLibraryMain>
        <MediaLibraryToolbar>
          <h2>Knihovna médií</h2>
          <MediaLibraryToolbarTools>
            <ToolboxMedia
              {...{
                handleUploadMediaOpen,
              }}
            />
          </MediaLibraryToolbarTools>
        </MediaLibraryToolbar>
        <MediaLibraryList>
          {media.data?.length && (
            <MediaTable
              {...{
                media,
                getData,
                handleUploadMediaOpen,
              }}
            />
          )}
        </MediaLibraryList>
      </MediaLibraryMain>
      <MediaForm
        {...{
          editMediaOpen,
          handleEditMediaClose,
          handleMediaEditDialogClearFile,
          handleMediaUploadFile,
          handleMediaUploadDialogClearFile,
          handleUploadMediaClose,
          handleUploadMediaCloseAndSave,
          handleUploadMediaChange,
          mediaData,
          uploadMediaOpen,
          noFileSnackOpen,
          handleNoFileSnackClose,
        }}
      />
    </MediaLibraryWrapper>
  );
};

MediaLibrary.propTypes = {};

export default MediaLibrary;
