import PropTypes from 'prop-types';
import MenuItem from './MenuItem';
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const MainMenuWrapper = styled("div")(({theme}) => ({
  height: "100%",
  background: theme.palette.tertiary.main,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  justifyContent: "space-between",
}));

const MainMenuContainer = styled("div")(({theme}) => ({
  height: "calc(100vh - 120px)",
  position: "sticky",
  top: "100px",
  background: theme.palette.tertiary.main,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  justifyContent: "space-between",
}));
// STYLED COMPONENTS BLOCK END

const MainMenu = ({adminData}) => {
  return (
    <MainMenuWrapper>
      <MainMenuContainer>
      <div>
        {/*<MenuItem menuItem={{ name: '', displayName: 'Úvod' }} />*/}
        {adminData?.length &&
        adminData.map((menuItem) => (
          <MenuItem {...{menuItem}} key={menuItem.id}/>
        ))}
      <MenuItem menuItem={{name: 'media', displayName: 'Media'}}/>
      </div>
      <div>
        <MenuItem menuItem={{name: 'settings', displayName: 'Nastavení'}} icon={"/img/icon/settings.svg"} serviceItem={true} />
      </div>
      </MainMenuContainer>
    </MainMenuWrapper>
  );
};

MainMenu.propTypes = {
  adminData: PropTypes.array,
};

export default MainMenu;
