import React, {useEffect} from 'react';
import {useAuth} from '../security/auth_context';
import Loading from '../components/Loading/Loading';
import {useParams} from 'react-router-dom';
import {useAdminContext} from '../context/context';
import MainMenu from '../componentsAdmin/MainMenu';
import {Header} from '../components/Header/Header';
import Dashboard from '../componentsAdmin/Dashboard';
import ContentLibrary from '../componentsAdmin/ContentLibrary';
import MediaLibrary from '../componentsAdmin/MediaLibrary';
import {Landing} from './Landing';
import {styled} from "@mui/styles";
import Settings from "../componentsAdmin/Settings/Settings";

// STYLED COMPONENTS BLOCK START
const AdminWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flex: 1,
  backgroundColor: theme.palette.background.default,
  position: "relative",
}));

const AdminContainer = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.default,
  height: "100vh",
}));
// STYLED COMPONENTS BLOCK END

export const Admin = () => {
  const {authentication, isAuthenticated, loading, getTokenSilently} =
    useAuth();
  const {admin, getAdmin} = useAdminContext();
  const {contentId} = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        await getTokenSilently();
        // Send a GET request to the server and add the signed in user's access token in the Authorization header
        if (isAuthenticated) {
          // const response = await fetch(`${apiRoot}/v1/admin`, {
          //   headers: {
          //     Authorization: `Bearer ${authentication.token}`,
          //   },
          // });

          // const responseData = await response.json();

          // setApiData(responseData);
          getAdmin(authentication.token);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getData();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Landing/>;
  }

  if (isAuthenticated && loading) {
    return <Loading/>;
  }

  return (
    <AdminContainer>
      <Header headerThemeType="header_light" logoType="logo"/>
      <AdminWrapper>
        {admin?.data.length && <MainMenu adminData={admin.data}/>}
        {contentId === undefined ? (
          <Dashboard/>
        ) : contentId === "settings" ? (
          <Settings/>
        ) : contentId === 'media' ? (
          <MediaLibrary/>
        ) : (
          <ContentLibrary {...{contentId}} />
        )}
      </AdminWrapper>
    </AdminContainer>
  );
};
