import Footer from '../components/Footer/Footer';
import {Header} from '../components/Header/Header';
import personalizace from '../images/crayons.svg';
import rozsiritelnost from '../images/puzzle.svg';
import rychlost from '../images/rocket.svg';
import {useAuth} from "../security/auth_context";
import {useEffect} from "react";
import {styled} from "@mui/system"

// STYLED COMPONENTS BLOCK START
const LandingWrapper = styled("div")(() => ({
  height: "100vh"
}));

const HeroWrapper = styled("div")(({theme}) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.up('lg')]: {
    height: "50vh"
  }
}));

const MainHeading = styled('h1')(({theme}) => ({
  color: theme.palette.secondary.contrastText,
  textTransform: "uppercase",
  fontWeight: 300,
  fontSize: 26,
  padding: theme.spacing(3),
  letterSpacing: 1,
  lineHeight: "60px",
  margin: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: 48,
    lineHeight: "75px",
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 60,
    lineHeight: "90px",
    padding: theme.spacing(13),
    letterSpacing: theme.spacing(3),
    width: "70%",
  }
}));

const SellingPointWrapper = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(5),
}));

const SellingPointsHeading = styled("h2")(({theme}) => ({
  fontSize: 22,
  color: theme.palette.secondary.main,
  margin: 0,
  [theme.breakpoints.up('lg')]: {
    fontSize: 32,
  }
}));

const SellingPoints = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "flex-start",
  justifyContent: "space-between",
  margin: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    flexDirection: "row",
    margin: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    width: "80%",
  }
}));

const SellingPointItem = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  [theme.breakpoints.up('md')]: {
    flexDirection: "column",
    alignItems: "center",
  },
  h3: {
    fontSize: "16px",
    [theme.breakpoints.up('md')]: {
      textAlign: "center",
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: "22px",
    },
  },
  img: {
    marginRight: "11px",
    width: "56px",
    height: "56px",
    "&.rocket": {
      height: "48px",
    },
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      order: 2,
    },
  }
}));
// STYLED COMPONENTS BLOCK FINISH

export const Landing = () => {
  const {getTokenSilently} =
    useAuth();

  useEffect(() => {
    getTokenSilently()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <LandingWrapper>
        <Header onLandingPage={true}/>
        <HeroWrapper>
          <MainHeading>SpaceBarKeepers backend na míru pro Vás</MainHeading>
        </HeroWrapper>
        <SellingPointWrapper>
          <SellingPointsHeading>Vaše výhody</SellingPointsHeading>
          <SellingPoints>
            <SellingPointItem>
              <img src={personalizace} alt="ikona pastelek"/>
              <h3>Neomezená personalizace</h3>
            </SellingPointItem>
            <SellingPointItem>
              <img src={rozsiritelnost} alt="ikona puzzle"/>
              <h3>Snadná rozšiřitelnost</h3>
            </SellingPointItem>
            <SellingPointItem>
              <img className="rocket" src={rychlost} alt="ikona rakety"/>
              <h3>Rychlost a spolehlivost</h3>
            </SellingPointItem>
          </SellingPoints>
        </SellingPointWrapper>
        <Footer/>
      </LandingWrapper>
    </>
  );
};
