import React from 'react';
import PropTypes from 'prop-types';
import {TextField, Typography} from "@mui/material";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const LinkFragmentWrapper = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "flex-end"
}));

const TextFieldStyled = styled(TextField)(({theme}) => ({
  '& > :not(style)': { marginLeft: 10 },
}));
// STYLED COMPONENTS BLOCK END

const LinkFragment = ({editData, fragmentItem, handleChangeDataArrayItemChange}) => {
  return (
    <LinkFragmentWrapper key={fragmentItem.id}>
      <Typography>{fragmentItem.name}{": "}</Typography>
      <TextFieldStyled
        size="small"
        variant="standard"
        id={fragmentItem.id + 0}
        label="Popis odkazu"
        value={editData[fragmentItem.id] ? editData[fragmentItem.id][0] : ''}
        onChange={(e) =>
          handleChangeDataArrayItemChange(
            fragmentItem.id,
            0,
            e.target.value,
          )
        }
      />
      <TextFieldStyled
        size="small"
        variant="standard"
        id={fragmentItem.id + 1}
        label="URL adresa odkazu"
        value={editData[fragmentItem.id] ? editData[fragmentItem.id][1] : ''}
        onChange={(e) =>
          handleChangeDataArrayItemChange(
            fragmentItem.id,
            1,
            e.target.value,
          )
        }
      />
    </LinkFragmentWrapper>
  );
};

LinkFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataArrayItemChange: PropTypes.func.isRequired,
};

export default LinkFragment;
