import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const MenuItemWrapper = styled("div")(({icon, serviceItem, theme}) => ({
  margin: theme.spacing(3),
  cursor: "pointer",
  whiteSpace: "nowrap",
  "& a": {
    color: theme.palette.tertiary.contrastText,
    textDecoration: icon ? "none" : "underline",
    textUnderlineOffset: "3px",
    textTransform: "uppercase",
    fontWeight: "700",
    letterSpacing: "1.5px",
    fontSize: serviceItem ? "16px" : "20px",
    "&:hover": {
      color: theme.palette.button.primary.hoverBackground,
    }
  },
  "& img": {
    height: 20,
    verticalAlign: "sub",
    marginRight: 5,
  },
}));
// STYLED COMPONENTS BLOCK END

const MenuItem = ({menuItem, icon = null, serviceItem}) => {
  return (
    <MenuItemWrapper {...{icon, serviceItem}}>
      {icon && <img src={icon} alt={menuItem.name} />}
      <Link to={`/admin/${menuItem.name}`}>{menuItem.displayName}</Link>
    </MenuItemWrapper>
  );
};

MenuItem.propTypes = {
  menuItem: PropTypes.object.isRequired,
  icon: PropTypes.string,
  serviceItem: PropTypes.bool,
};

export default MenuItem;
