import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link, useNavigate} from "react-router-dom";
import ToolboxContentEditor from "./ToolboxContentEditor";
import {
  Button,
} from "@material-ui/core";
import EditorFragments from "./EditorFragments";
import {useAdminContext} from "../../context/context";
import {useAuth} from "../../security/auth_context";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {saveContentItem} from "../../helpers/saveContentItem";
import {getConfig} from "../../config";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const ContentEditorWrapper = styled("div")(({theme}) => ({
  display: "flex",
  width: "100%",
  borderRight: `2px solid ${theme.palette.tertiary.main}`,
  overflow: "hidden",
}));

const ContentEditorMain = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  maxWidth: "1100px",
  margin: `${theme.spacing(5)} ${theme.spacing(9)}`,
}));

const ContentEditorToolbar = styled("div")(({theme}) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.up('md')]: {
    flexDirection: "row",
  },
  "& h2": {
    margin: 0,
  }
}));

const ContentEditorToolbarTools = styled("div")(({theme}) => ({
  "& button": {
    margin: `${theme.spacing(2)} 0`,
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(2)}`
    },
  }
}));

const ContentEditorFooter = styled("div")(({theme}) => ({
  display: "flex",
  justifyContent: "flex-end",
}));
// STYLED COMPONENTS BLOCK END

const ContentEditor = ({admin, contentId, itemId}) => {
  const {contentLib, getContentLib} = useAdminContext()
  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  const {apiRoot} = getConfig();
  const navigate = useNavigate();

  const [editData, setEditData] = useState({})
  const [editDataLoaded, setEditDataLoaded] = useState(false)
  const [dialogGoBackWithoutSavingOpen, setDialogGoBackWithoutSavingOpen] = useState(false)

  useEffect(() => {
    console.log(editDataLoaded)
  }, [editDataLoaded])

  const getData = async () => {
    try {
      await getTokenSilently();
      if (isAuthenticated) {
        await getContentLib(authentication.token, contentId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [contentId, itemId]);

  useEffect(() => {
    getData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function loadEditData() {
      await itemId && contentLib?.data?.length && setEditData({...contentLib?.data?.find((item) => item.id === parseInt(itemId))})
      itemId && contentLib?.data?.length && setEditDataLoaded(true)
    }

    loadEditData()
  }, [itemId, contentLib?.data])

  const handleSave = async () => {
    const response = await saveContentItem(apiRoot, authentication, contentId, editData, getTokenSilently, isAuthenticated)
    if (response?.ResponseCode === 1) {
      navigate(`/admin/${contentId}`);
    } else {
      alert("Něco se nezdařilo. Data neuložena.")
    }
  }

  const handleChangeData = (event) => {
    setEditData({...editData, [event.target.id]: event.target.value})
  }

  const handleChangeDataByFieldId = (newValue, fieldId) => {
    setEditData({...editData, [fieldId]: newValue})
  }

  const handleChangeRichTextDataByFieldId = (editorState, fieldId) => {
    setEditData({...editData, [fieldId]: draftToHtml(convertToRaw(editorState.getCurrentContent()))})
  }

  const handleClearDataByFieldId = (fieldId) => {
    console.log("cleardata")
    setEditData({...editData, [fieldId]: undefined})
  }

  const handleChangeDataSwitchChecked = (event) => {
    setEditData({...editData, [event.target.id]: event.target.checked})
  };

  const handleChangeDataAppendToArray = (fieldId, itemToAppend) => {
    console.log("appecd", fieldId, itemToAppend)
    const dataArray = editData[fieldId] ? editData[fieldId] : [];
    dataArray.push(itemToAppend)
    setEditData({...editData, [fieldId]: dataArray});
  };

  const handleChangeDataRemoveFromArray = (fieldId, itemToRemove) => {
    const dataArray = editData[fieldId];
    const newDataArray = dataArray.filter(
      (item) => item !== itemToRemove,
    );
    setEditData({...editData, [fieldId]: newDataArray});
  };

  const handleChangeDataArrayItemChange = (fieldId, position, value) => {
    const arrayValue = editData[fieldId] ? editData[fieldId] : []
    arrayValue[position] = value
    setEditData({...editData, [fieldId]: arrayValue})
  };

  const handleChangeDataDropdown = (fieldId, value, options) => {
    console.log("dropdown", {selected: value, options})
    setEditData({...editData, [fieldId]: {selected: value, options}})
  }

  const handleGoBackWithoutSaving = (e) => {
    e.preventDefault()
    setDialogGoBackWithoutSavingOpen(true)
  }

  const handleCloseDialogGoBackWithoutSaving = () => {
    setDialogGoBackWithoutSavingOpen(false)
  }

  const handleReallyGoBackWithoutSaving = () => {
    navigate(`/admin/${contentId}`);
  }

  useEffect(() => {
    console.log("editData", editData)
  }, [editData])

  return (
    <ContentEditorWrapper>
      <ContentEditorMain>
        <ContentEditorToolbar>
          <div>
            <Link to={`/admin/${contentId}`} onClick={handleGoBackWithoutSaving}>
              {`Zpět na ${admin.data.length && admin.data.find((item) => item.name === contentId).displayName}`}
            </Link>
          </div>
          <h2>
            {itemId ? "Editace položky" : "Nová položka"}
          </h2>
          <ContentEditorToolbarTools>
            <ToolboxContentEditor {...{handleSave, handleGoBackWithoutSaving}} />
          </ContentEditorToolbarTools>
        </ContentEditorToolbar>
        <div>
          {admin && <EditorFragments {...{
            admin,
            contentId,
            editData,
            editDataLoaded,
            handleChangeData,
            handleChangeDataByFieldId,
            handleChangeRichTextDataByFieldId,
            handleClearDataByFieldId,
            handleChangeDataSwitchChecked,
            handleChangeDataArrayItemChange,
            handleChangeDataRemoveFromArray,
            handleChangeDataAppendToArray,
            handleChangeDataDropdown
          }} />}
        </div>
        <ContentEditorFooter>
          <ContentEditorToolbarTools>
            <ToolboxContentEditor {...{handleSave, handleGoBackWithoutSaving}} />
          </ContentEditorToolbarTools>
        </ContentEditorFooter>
      </ContentEditorMain>

      <Dialog
        open={dialogGoBackWithoutSavingOpen}
        onClose={handleCloseDialogGoBackWithoutSaving}
        aria-labelledby="goBackWithoutSavingOpen-dialog-title"
      >
        <DialogTitle id="goBackWithoutSavingOpen-dialog-title">
          {"Jít zpět bez uložení změn?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Chcete opravdu odejít bez uložení? Všechny neuložené změny budou trvale ztraceny.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialogGoBackWithoutSaving}>
            Neodcházet
          </Button>
          <Button onClick={handleReallyGoBackWithoutSaving} autoFocus>
            Odejít
          </Button>
        </DialogActions>
      </Dialog>
    </ContentEditorWrapper>
  );
};

ContentEditor.propTypes = {
  admin: PropTypes.object,
  contentId: PropTypes.string.isRequired,
  itemId: PropTypes.string,
};

export default ContentEditor;
