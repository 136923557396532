import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  IconButton, styled,
  // Tab,
  Tabs,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import documentImg from "../../images/document.png";


const UploadDialog = ({
                        handleMediaUploadDialogClearFile,
                        handleMediaUploadFile,
                        handleUploadDialogClose,
                        handleUploadDialogCloseAndSave,
                        mediaData,
                        uploadDialogOpen
                      }) => {
  const [value, setValue] = useState("upload")

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <>
      <Dialog open={uploadDialogOpen} onClose={handleUploadDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Přidání média</DialogTitle>
        <DialogContent>
          <Tabs value={value} onChange={handleChange} centered>
            {/*<Tab label="Nahrát" value="upload"/>*/}
            {/*<Tab label="Přidat z URL" value="url"/>*/}
          </Tabs>
          {value === "upload" && (
            (!!mediaData.src && mediaData.src !== "")
              ? (
                <Card style={{width: "300px"}}>
                  <CardHeader
                    action={
                      <IconButton aria-label="clear"
                                  onClick={() => handleMediaUploadDialogClearFile(mediaData?.id, mediaData?.name)}>
                        <ClearIcon/>
                      </IconButton>
                    }
                    subheader={mediaData?.desc}
                  />
                  <CardMedia
                    component="img"
                    // height="194"
                    image={mediaData.type === "image" ? mediaData.src : documentImg}
                    alt={mediaData?.desc}
                  />
                </Card>
              )
              : (
                <div className="upload-field">
                  <label htmlFor="uploader">
                    <Input
                      accept="image/*,.docx,.pdf,.pptx,.txt"
                      id="uploader"
                      type="file"
                      onChange={handleMediaUploadFile}
                    />
                    <Button variant="contained" component="span">
                      Vybrat
                    </Button>
                  </label>
                </div>
              ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadDialogClose} color="primary">
            Zrušit
          </Button>
          <Button onClick={handleUploadDialogCloseAndSave} color="primary">
            Přidat médium
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
};

UploadDialog.propTypes = {
  handleMediaUploadDialogClearFile: PropTypes.func.isRequired,
  handleMediaUploadFile: PropTypes.func.isRequired,
  handleUploadDialogClose: PropTypes.func.isRequired,
  handleUploadDialogCloseAndSave: PropTypes.func.isRequired,
  mediaData: PropTypes.object,
  uploadDialogOpen: PropTypes.bool.isRequired,
};

export default UploadDialog;
