import LoginForm from './LoginForm';
import {useAuth} from '../../../security/auth_context';
import {useNavigate} from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {Avatar} from "@mui/material";
import UniversalButton from "./../../Button"
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const AuthWrapper = styled("div")(({theme}) => ({
  width: "100%",
  "& div": {
    padding: `0 ${theme.spacing(5)}`,
  },
  "& .accountInfo": {
    display: "flex",
    "& .accountName": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& h4": {
        margin: 0,
      },
      "& h6": {
        margin: 0,
      }
    }
  }
}))
// STYLED COMPONENTS BLOCK END

export const Auth = ({onLandingPage}) => {
  const {isAuthenticated, loading, logout, authentication} = useAuth();
  const navigate = useNavigate();

  return (
    <AuthWrapper>
      {!isAuthenticated && <LoginForm/>}
      {/* tady by neměla být závislost na loading, předělat */}
      {isAuthenticated && !loading && (
        <div>
          <div className="accountInfo">
            <Avatar style={{padding: 0, justifyContent: "center"}} variant="rounded"><AccountCircleIcon/></Avatar>
            <div className="accountName">
              <h4>{authentication.userName}</h4>
              <h6>{authentication.customerId}</h6>
            </div>
          </div>
          {onLandingPage && <UniversalButton
            variant="contained"
            onClick={() => navigate("/admin")}
          >
            Administrace
          </UniversalButton>}
          <UniversalButton
            color="primary"
            variant="contained"
            onClick={() => logout({returnTo: window.location.origin})}
          >
            Odhlásit se
          </UniversalButton>
        </div>
      )}
    </AuthWrapper>
  );
};
