import React, {useEffect} from 'react';
import {useAuth} from '../security/auth_context';
import Loading from '../components/Loading/Loading';
import {useParams} from 'react-router-dom';
import {useAdminContext} from '../context/context';
import MainMenu from '../componentsAdmin/MainMenu';
import {Header} from '../components/Header/Header';
import {Landing} from './Landing';
import ContentEditor from "../componentsAdmin/ContentEditor";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const CreateItemWrapper = styled("div")(({theme}) => ({
  display: "flex",
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));
// STYLED COMPONENTS BLOCK END

export const CreateItem = () => {
  const {authentication, isAuthenticated, loading, getTokenSilently} = useAuth();
  const {admin, getAdmin} = useAdminContext();
  const {contentId} = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        await getTokenSilently();
        // Send a GET request to the server and add the signed in user's access token in the Authorization header
        if (isAuthenticated) {
          // const response = await fetch(`${apiRoot}/v1/admin`, {
          //   headers: {
          //     Authorization: `Bearer ${authentication.token}`,
          //   },
          // });

          // const responseData = await response.json();

          // setApiData(responseData);
          getAdmin(authentication.token);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getData();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Landing/>;
  }

  if (isAuthenticated && loading) {
    return <Loading/>;
  }

  return (
    <>
      <Header headerThemeType="header_light" logoType="logo"/>
      <CreateItemWrapper className="edit-item">
        {admin?.data.length && <MainMenu adminData={admin.data}/>}
        <ContentEditor {...{admin, contentId}} />
      </CreateItemWrapper>
    </>
  );
};
