import React from 'react';
import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import {TextField} from "@material-ui/core";

const DatetimeFragment = ({editData, fragmentItem, handleChangeDataByFieldId}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} key={fragmentItem.id}>
      <DateTimePicker
        renderInput={(props) => <TextField {...props} />}
        label={fragmentItem.name}
        value={editData[fragmentItem.id] ? editData[fragmentItem.id] : null}
        ampm={false}
        required={!!fragmentItem.isRequired}
        onChange={(newDate) => handleChangeDataByFieldId(newDate, fragmentItem.id)}
      />
    </LocalizationProvider>);
};

DatetimeFragment.propTypes = {
  editData: PropTypes.object.isRequired,
  fragmentItem: PropTypes.object.isRequired,
  handleChangeDataByFieldId: PropTypes.func.isRequired,
};

export default DatetimeFragment;
