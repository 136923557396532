import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {useAuth} from "../../../security/auth_context";
import TextField from "@material-ui/core/TextField";
import {useNavigate} from "react-router-dom";
import UniversalButton from "../../Button";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const LoginFormWrapper = styled("div")(({theme}) => ({
  "& form": {
    display: "flex",
    flexDirection: "column",
    "& input": {
      padding: theme.spacing(3),
    },
    "& button": {
      margin: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: "row",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3)
    }
  },
  "& div": {
    margin: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      margin: 0,
    }
  }
}))
// STYLED COMPONENTS BLOCK END

const LoginForm = () => {
  const navigate = useNavigate();
  const {error, hasError} = useAuth();

  const [name, setName] = useState("");
  const [pass, setPass] = useState("");

  const {getToken} = useAuth();

  const handleSubmit = (e) => {
    e.preventDefault();
    getToken(name, pass)
    navigate('/admin');
  }

  return (
    <>
      <LoginFormWrapper>
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField id="login" label="Jméno" onChange={e => setName(e.target.value)}/>
          <TextField id="pass" label="Heslo" type="password" onChange={e => setPass(e.target.value)}/>
          <UniversalButton variant="contained" color="primary" type="submit">
            Přihlásit se
          </UniversalButton>
        </form>
        {hasError && error && <div>{error}</div>}
      </LoginFormWrapper>
    </>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
