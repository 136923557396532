export const uploadMedia = async (event, apiRoot, authentication, getTokenSilently, isAuthenticated) => {
  let formData = new FormData();
  formData.append("uploadFile", event.target.files[0]);

  try {
    await getTokenSilently();
  } catch (error) {
    console.error(error);
  }
  if (isAuthenticated) {
    const response = await fetch(`${apiRoot}/v1/media`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${await authentication.token}`,
      },
      body: formData
    });
    const responseJSON = await response.json()
    return responseJSON
  }
}
