import {createTheme} from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 800,
        lg: 1100,
        xl: 1400
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: '#000',
        contrastText: '#fff',
      },
      secondary: {
        main: '#780044',
        contrastText: '#fff',
      },
      tertiary: {
        main: '#d3d3d3',
        contrastText: '#000',
      },
      button: {
        primary: {
          background: '#3a0ca3',
          hoverBackground: '#780044',
          text: '#fff'
        },
        secondary: {
          background: '#e30b5d',
          hoverBackground: '#780044',
          text: '#fff'
        },
      },
      action: {
        active: "#646464",
        disabled: "#d3d3d3",
      },
      grey: {
        50: "#F3F6F9",
        100: "#EAEEF3",
        200: "#E5E8EC",
        300: "#d3d3d3",
        400: "#BFC7CF",
        500: "#AAB4BE",
        600: "#7F8E9D",
        700: "#646464",
        800: "#2F3A45",
        900: "#20262D",
        A100: "#f5f5f5",
        A200: "#eeeeee",
        A400: "#bdbdbd",
        A700: "#616161"
      },
      text: {
        primary: "#000",
        secondary: "#2F3A45" //datetimepicker inside hours
      },
      background: {
        default: '#fff'
      }
    },
    spacing: [0, 3, 6, 12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72],
    typography: {
      fontFamily: "'Raleway', sans-serif",
      body1: {
        fontFamily: "'Raleway', sans-serif",
      },
      pxToRem: ((size) => `${(size / 16) * (14 / 14)}rem`) // stolen from MUI docs and very much simplified
    },
    // transitions: createTransitions(),
  }
)

export default theme
