import base64 from "base-64";
import { getConfig } from "../config";

export const userService = {
  login,
  session,
  logout,
};

async function login(username, password) {
  const { apiRoot } = getConfig();
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      Authorization: `Basic ${base64.encode(`${username}:${password}`)}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  };

  const response = await fetch(`${apiRoot}/v1/auth/token`, requestOptions);
  const token = await handleResponse(response);
  // login successful if there's a user in the response
  if (token) {
    // store user details and basic auth credentials in local storage
    // to keep user logged in between page refreshes
    // user.authdata = window.btoa(username + ":" + password);
    // localStorage.setItem("user", JSON.stringify(user));
  }
  return token;
}

async function session() {
  const { apiRoot } = getConfig();
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  };
  const response = await fetch(`${apiRoot}/v1/auth/session`, requestOptions);
  const token = await handleResponse(response);
  // login successful if there's a user in the response
  if (token) {
    // store user details and basic auth credentials in local storage
    // to keep user logged in between page refreshes
    // user.authdata = window.btoa(username + ":" + password);
    // localStorage.setItem("user", JSON.stringify(user));
  }
  return token;
}

async function logout() {
  // remove user from local storage to log user out
  const { apiRoot } = getConfig();
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  };

  await fetch(`${apiRoot}/v1/auth/revoke`, requestOptions);
  return;
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text;
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
        // location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
