import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import styled from "styled-components";
import UserSettings from "./UserSettings";
import {Snackbar} from "spacebar-ui"

const SettingsContainer = styled.div(props => ({
  width: "100%",
  "& h2": {
    textAlign: "center",
  },
}));

const Settings = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarVariant, setSnackbarVariant] = useState(undefined);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    !snackbarOpen && setSnackbarVariant(undefined)
  }, [snackbarOpen])

  return (
    <SettingsContainer>
      <h2>
        Nastavení
      </h2>
      <UserSettings {...{setSnackbarOpen, setSnackbarMessage, setSnackbarVariant}} />
      <Snackbar
        snackbarOpen={snackbarOpen}
        setSnackbarOpen={setSnackbarOpen}
        snackbarMessage={snackbarMessage}
        snackbarVariant={snackbarVariant}
      />
    </SettingsContainer>
  );
};

// Settings.propTypes = {
//
// };

export default Settings;
