// import PropTypes from 'prop-types'
import logo from '../../images/logo.png';
import logoWhite from '../../images/logo_white.png';
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const LogoWrapper = styled("div")(({theme}) => ({
    width: "150px",
    height: "auto",
    margin: `0 ${theme.spacing(5)}`,
    "& img": {
      maxWidth: "100%",
    },
    [theme.breakpoints.up('md')]: {
      width: "200px"
    },
  }))
;
// STYLED COMPONENTS BLOCK END

const Logo = ({logoType}) => {
  return (
    <LogoWrapper>
      {logoType === 'whiteLogo' ? (
        <img src={logoWhite} alt=""/>
      ) : (
        <img src={logo} alt=""/>
      )}
    </LogoWrapper>
  );
};

Logo.propTypes = {};

export default Logo;
