import React from 'react';
import PropTypes from 'prop-types';
import {
  CONTENT_FIELD_TYPE_CHECKBOX_WITH_TWO_TEXT_FIELDS,
  CONTENT_FIELD_TYPE_DATETIME,
  CONTENT_FIELD_TYPE_DROPDOWN, CONTENT_FIELD_TYPE_FULLTEXT_EDITOR,
  CONTENT_FIELD_TYPE_GALLERY,
  CONTENT_FIELD_TYPE_IMAGE,
  CONTENT_FIELD_TYPE_LINK, CONTENT_FIELD_TYPE_MEDIA,
  CONTENT_FIELD_TYPE_TEXTAREA,
  CONTENT_FIELD_TYPE_TEXTFIELD,
  CONTENT_FIELD_TYPE_TOGGLE
} from "../../../constants/fieldTypes";
import TextfieldFragment from "./TextfieldFragment";
import DatetimeFragment from "./DatetimeFragment";
import TextareaFragment from "./TextareaFragment";
import ImagePickerFragment from "./ImagePickerFragment";
import ImageCardFragment from "./ImageCardFragment";
import ToggleFragment from "./ToggleFragment";
import CheckBoxWithTwoTextFieldsFragment from "./CheckBoxWithTwoTextFieldsFragment";
import LinkFragment from "./LinkFragment";
import GalleryFragment from "./GalleryFragment";
import DropdownFragment from "./DropdownFragment";
import MediaPickerFragment from "./MediaPickerFragment";
import RichtextEditorFragment from "./RichtextEditorFragment";

const EditorFragments = ({
                           admin,
                           contentId,
                           editData,
                           editDataLoaded,
                           handleChangeData,
                           handleChangeDataByFieldId,
                           handleChangeRichTextDataByFieldId,
                           handleClearDataByFieldId,
                           handleChangeDataSwitchChecked,
                           handleChangeDataArrayItemChange,
                           handleChangeDataRemoveFromArray,
                           handleChangeDataAppendToArray,
                           handleChangeDataDropdown,
                         }) => {
  return (
    <>
      {admin.data.length && admin.data.find(item => item.name === contentId)?.fields
      && admin.data.find(item => item.name === contentId).fields.map((fragmentItem) => {
        switch (fragmentItem.type) {
          case CONTENT_FIELD_TYPE_CHECKBOX_WITH_TWO_TEXT_FIELDS:
            return (
              <CheckBoxWithTwoTextFieldsFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeDataArrayItemChange}} />
            );
          case CONTENT_FIELD_TYPE_DATETIME:
            return (
              <DatetimeFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeDataByFieldId}} />
            );
          case CONTENT_FIELD_TYPE_DROPDOWN:
            //this condition and double component has to be there due to MUI select not able to change initial state after first
            //for that reason there has to be complete rerender
            if (!!editData[fragmentItem.id]?.selected || editData[fragmentItem.id]?.selected === 0) {
              return (
                <DropdownFragment key={`${fragmentItem.id}true`} {...{editData, fragmentItem, handleChangeDataDropdown}} />
              );
            } else {
              return (
                <DropdownFragment key={`${fragmentItem.id}false`} {...{editData, fragmentItem, handleChangeDataDropdown}} />
              );
            }
          case CONTENT_FIELD_TYPE_FULLTEXT_EDITOR:
            return (
              <RichtextEditorFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeRichTextDataByFieldId, editDataLoaded}} />
            )
          case CONTENT_FIELD_TYPE_GALLERY:
            return (
             <GalleryFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeDataRemoveFromArray, handleChangeDataAppendToArray}} />
            );
          case CONTENT_FIELD_TYPE_IMAGE:
            if (editData[fragmentItem.id] && editData[fragmentItem.id] !== '') {
              return (
                <ImageCardFragment key={fragmentItem.id} {...{editData, fragmentItem, handleClearDataByFieldId}} />
              );
            } else {
              return (
                <ImagePickerFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeDataByFieldId}} />
              );
            }
          case CONTENT_FIELD_TYPE_LINK:
            return (
              <LinkFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeDataArrayItemChange}} />
            );
          case CONTENT_FIELD_TYPE_MEDIA:
            return (
              <MediaPickerFragment {...{editData, fragmentItem, handleChangeDataByFieldId}} />
            );
          case CONTENT_FIELD_TYPE_TEXTAREA:
            return (
              <TextareaFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeData}} />
            );
          case CONTENT_FIELD_TYPE_TEXTFIELD:
            return (
              <TextfieldFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeData}} />
            );
          case CONTENT_FIELD_TYPE_TOGGLE:
            if (!editData[fragmentItem.id]) editData[fragmentItem.id] = false; //if there are no data for this switch, set default value to false
            return (
              <ToggleFragment key={fragmentItem.id} {...{editData, fragmentItem, handleChangeDataSwitchChecked}} />
            );



          default:
            // eslint-disable-next-line array-callback-return
            return;
        }
      })
      }
    </>
  );
};

EditorFragments.propTypes = {
  admin: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  editData: PropTypes.object.isRequired,
  editDataLoaded: PropTypes.bool.isRequired,
  handleChangeData: PropTypes.func.isRequired,
  handleChangeDataByFieldId: PropTypes.func.isRequired,
  handleChangeRichTextDataByFieldId: PropTypes.func.isRequired,
  handleClearDataByFieldId: PropTypes.func.isRequired,
  handleChangeDataSwitchChecked: PropTypes.func.isRequired,
  handleChangeDataArrayItemChange: PropTypes.func.isRequired,
  handleChangeDataRemoveFromArray: PropTypes.func.isRequired,
  handleChangeDataAppendToArray: PropTypes.func.isRequired,
  handleChangeDataDropdown: PropTypes.func.isRequired,
};

export default EditorFragments;
