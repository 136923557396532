export const saveContentItem = async (apiRoot, authentication, contentId, editData, getTokenSilently, isAuthenticated, ) => {
  let numberOfRequired = 0;
  let numberFilled = 0;
  document.querySelectorAll('[required]').forEach((i) => {
    numberOfRequired++;
    if (i.value) numberFilled++;
  });
  if (numberOfRequired !== numberFilled) {
    alert('Vyplňte všechna povinná pole!');
    return false;
  }

  try {
    await getTokenSilently();
  } catch (error) {
    console.error(error);
  }
  if (isAuthenticated) {
    const response = await fetch(`${apiRoot}/v1/${contentId}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${await authentication.token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editData)
    });
    console.log("odeslaná data", JSON.stringify(editData))
    return (response.json());
  }
}
