import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import MuiAlert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {v4 as uuidv4} from 'uuid';
import {useAuth} from '../../security/auth_context';
import {getConfig} from '../../config';
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/styles";

// STYLED COMPONENTS BLOCK START
const TableContainerStyled = styled(TableContainer)(({theme}) => ({
  "& .content-table": {
    width: "100%",
  },
  "& td": {
    textAlign: "center",
  }
}));
// STYLED COMPONENTS BLOCK END

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ContentTable = ({
                        contentType,
                        contentId,
                        contentLib,
                        getData,
                      }) => {
  const classes = useStyles();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [deleteItemOpen, setDeleteItemOpen] = useState(false);
  const [deleteSnackSuccess, setDeleteSnackSuccess] = useState(false);
  const [deleteSnackFailure, setDeleteSnackFailure] = useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const {authentication, isAuthenticated, getTokenSilently} = useAuth();
  const {apiRoot} = getConfig();
  const navigate = useNavigate();

  useEffect(() => {
    // reset columns
    setColumns([]);
    // set column for every item from contentType.displayFields
    contentType.displayFields.forEach((item) => {
      setColumns((columns) => [
        ...columns,
        {
          columnName: contentType.fields.find((field) => field.id === item)
            .name,
          columnId: item,
        },
      ]);
    });
  }, [contentType]);

  useEffect(() => {
    // reset rows
    setRows([]);
    // regexp identifying timestamp
    const regexExpDate =
      /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}[+][0-9]{2}[:][0-9]{2}/g
    const regexExpDate2 =
      /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(\.[0-9]+)?([zZ]|([+-])([01]\d|2[0-3]):?([0-5]\d)?)?$/i;
    // from every data item...
    contentLib.data
      .sort((a, b) => {
        const dateASplit = a[contentType.orderBy[0]]?.split(/\.\D|[-]|[T]/);
        const dateA = dateASplit
          ? new Date(dateASplit[0], dateASplit[1] - 1, dateASplit[2])
          : contentType.orderBy[1] === 'asc'
            ? 1
            : 10 ** 100;
        const dateBSplit = b[contentType.orderBy[0]]?.split(/\.\D|[-]|[T]/);
        const dateB = dateBSplit
          ? new Date(dateBSplit[0], dateBSplit[1] - 1, dateBSplit[2])
          : contentType.orderBy[1] === 'asc'
            ? 1
            : 10 ** 100;
        return contentType.orderBy[1] === 'asc' ? dateA - dateB : dateB - dateA;
      })
      .forEach((item) => {
        const rowItem = {};
        // ... extract fields to match contentType.displayFields
        contentType.displayFields.forEach((contentTypeField) => {
          // if value is timestamp, convert it into readable date
          if (regexExpDate.test(item[contentTypeField]) || regexExpDate2.test(item[contentTypeField])) {
            rowItem[contentTypeField] = new Date(
              item[contentTypeField],
            ).toLocaleString('cs', {
              timeZone: "Europe/Prague",
              dateStyle: 'medium',
              timeStyle: 'short',
            });
          } else {
            rowItem[contentTypeField] = item[contentTypeField];
          }
        });
        // ad id for correct item identification
        rowItem['id'] = item.id;
        rowItem['published'] = item.published
        // push rowItem into rows
        setRows((rows) => [...rows, rowItem]);
      });
  }, [contentLib, contentType]);

  const handleDeleteItem = async (id) => {
    setDeleteItemOpen(false);

    try {
      await getTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (isAuthenticated) {
      const response = await fetch(`${apiRoot}/v1/${contentId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${authentication.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({itemID: id}),
      });
      const fetchReponse = await response.json();

      if (fetchReponse.ResponseCode === 1) {
        setDeleteSnackSuccess(true);
      } else {
        setDeleteSnackFailure(true);
      }
    }

    getData();
  };

  const handleDeleteItemOpen = (id) => {
    setDeleteItemOpen(true);
    setSelectedItem(id);
  };

  const handleDeleteItemClose = () => {
    setDeleteItemOpen(false);
  };

  const handleDeleteSnackSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleteSnackSuccess(false);
  };

  const handleDeleteSnackFailureClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setDeleteSnackFailure(false);
  };

  const handleEditItem = (itemId) => {
    navigate(`/admin/${contentId}/edititem/${itemId}`)
  }


  return (
    <>
      <TableContainerStyled component={Paper}>
        <Table className={classes.table} aria-label="sticky table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{fontWeight: '700'}}>
                Publikováno?
              </TableCell>
              {columns.map((item) => (
                <TableCell
                  key={uuidv4()}
                  align="center"
                  style={{fontWeight: '700'}}
                >
                  {item.columnName}
                </TableCell>
              ))}
              <TableCell align="center" style={{fontWeight: '700'}}>
                Úpravy
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  {row.published ? <CheckIcon/> : <ClearIcon/>}
                </TableCell>
                {columns.map((column) => (
                  <TableCell key={uuidv4()} align="center">
                    {row[column.columnId]}
                  </TableCell>
                ))}
                <TableCell align="center">
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditItem(row.id)}
                  >
                    <EditIcon color="primary"/>
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteItemOpen(row.id)}
                  >
                    <DeleteForeverIcon color="error"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerStyled>

      <Dialog
        open={deleteItemOpen}
        onClose={handleDeleteItemClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Smazat položku</DialogTitle>
        <DialogContent>
          Chystáte se smazat jednu z položek. Opravdu chcete pokračovat ve
          smazání?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteItemClose} color="primary">
            Zpět
          </Button>
          <Button
            onClick={() => handleDeleteItem(selectedItem)}
            color="secondary"
            variant="contained"
          >
            Smazat položku
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={deleteSnackSuccess}
        autoHideDuration={6000}
        onClose={handleDeleteSnackSuccessClose}
      >
        <Alert severity="success" onClose={handleDeleteSnackSuccessClose}>
          Položka úspěšně smazána!
        </Alert>
      </Snackbar>

      <Snackbar
        open={deleteSnackFailure}
        autoHideDuration={6000}
        onClose={handleDeleteSnackFailureClose}
      >
        <Alert severity="error" onClose={handleDeleteSnackFailureClose}>
          Při mazání položky došlo k chybě!
        </Alert>
      </Snackbar>
    </>
  );
};

ContentTable.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.object.isRequired,
  contentLib: PropTypes.object.isRequired,
  getData: PropTypes.func.isRequired,
};

export default ContentTable;
