import React from "react";
import {Route, Routes, BrowserRouter} from "react-router-dom";
import { Admin } from "./pages/Admin";
import { Landing } from "./pages/Landing";
import { createBrowserHistory } from "history";
import {EditItem} from "./pages/EditItem";
import {CreateItem} from "./pages/CreateItem";

const history = createBrowserHistory();

const App = () => {

  return (
      <BrowserRouter location={history.location} navigator={history}>
        <Routes>
          <Route path="/admin/:contentId/createitem" element={<CreateItem />} />
          <Route path="/admin/:contentId/edititem/:itemId" element={<EditItem />} />
          <Route path="admin" element={<Admin />} >
            <Route path=":contentId" element={<Admin />} />
          </Route>
          <Route path="/" element={<Landing />} />
        </Routes>
      </BrowserRouter>
  );
};

export default App;
