/**
 * @param {{fields:array}} data
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import UploadDialogMedia from './UploadDialogMedia';
import { Alert } from '@mui/material';
import documentImg from '../../images/document.png';

const MediaForm = ({
  editMediaOpen,
  handleEditMediaClose,
  handleMediaEditDialogClearFile,
  handleMediaUploadDialogClearFile,
  handleMediaUploadFile,
  handleNoFileSnackClose,
  handleUploadMediaChange,
  handleUploadMediaClose,
  handleUploadMediaCloseAndSave,
  mediaData,
  noFileSnackOpen,
  uploadMediaOpen,
}) => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const handleUploadDialogOpen = () => {
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = () => {
    setUploadDialogOpen(false)
    !!mediaData.id && handleMediaUploadDialogClearFile(mediaData.id)
  }
  const handleUploadDialogCloseAndSave = () => {
    setUploadDialogOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Dialog
        open={uploadMediaOpen}
        onClose={handleUploadMediaClose}
        aria-labelledby="responsive-dialog-title"
        fullScreen={fullScreen}
      >
        <DialogTitle id="form-dialog-title">
          {'Přidání položky médií'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="desc"
            label="Název média"
            onChange={handleUploadMediaChange}
          />
          {(mediaData.src && mediaData.src !== "") ? (
              (
                <div key={mediaData.name}>
                  <Card style={{width: "300px"}}>
                    <CardHeader
                      action={
                        <IconButton aria-label="clear"
                                    onClick={() => handleMediaUploadDialogClearFile(mediaData.id)}>
                          <ClearIcon/>
                        </IconButton>
                      }
                      subheader={mediaData?.desc}
                    />
                    <CardMedia
                      component="img"
                      // height="194"
                      image={mediaData.type === "image" ? mediaData.src : documentImg}
                      alt={mediaData?.desc}
                    />
                  </Card>
                </div>
              )
            ) :
            (
              <div>
                <p>Soubor: </p>
                <Button variant="contained" component="span" onClick={handleUploadDialogOpen}>
                  Přidat soubor
                </Button>
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUploadMediaClose} color="primary">
            Zrušit
          </Button>
          <Button onClick={handleUploadMediaCloseAndSave}>
            Přidat položku
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        open={editMediaOpen}
        onClose={handleEditMediaClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {'Editace položky médií'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="desc"
            label="Název média"
            value={mediaData.desc}
            onChange={handleUploadMediaChange}
          />
          {(mediaData.src && mediaData.src !== "") ? (
              (
                <div key={mediaData.name}>
                  <Card style={{width: "300px"}}>
                    <CardHeader
                      action={
                        <IconButton aria-label="clear"
                                    onClick={() => handleMediaEditDialogClearFile(mediaData.id)}>
                          <ClearIcon/>
                        </IconButton>
                      }
                      subheader={mediaData?.desc}
                    />
                    <CardMedia
                      component="img"
                      // height="194"
                      image={mediaData.type === "image" ? mediaData.src : documentImg}
                      alt={mediaData?.desc}
                    />
                  </Card>
                </div>
              )
            ) :
            (
              <div>
                <p>Soubor: </p>
                <Button variant="contained" component="span" onClick={handleUploadDialogOpen}>
                  Přidat soubor
                </Button>
              </div>
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleEditMediaClose}
            color="grey"
            variant="contained"
          >
            Zrušit
          </Button>
          <Button
            onClick={handleUploadMediaCloseAndSave}
            color="primary"
            variant="contained"
          >
            Uložit položku
          </Button>
        </DialogActions>
      </Dialog>

      <UploadDialogMedia
        {...{
          handleMediaUploadDialogClearFile,
          handleMediaUploadFile,
          handleUploadDialogClose,
          handleUploadDialogCloseAndSave,
          mediaData,
          uploadDialogOpen,
        }}
      />

      <Snackbar
        open={noFileSnackOpen}
        autoHideDuration={6000}
        onClose={handleNoFileSnackClose}
      >
        <Alert severity="error" onClose={handleNoFileSnackClose}>
          Nebyl vybrán žádný soubor k nahrání
        </Alert>
      </Snackbar>
    </>
  );
};

MediaForm.propTypes = {
  editMediaOpen: PropTypes.bool,
  handleEditMediaClose: PropTypes.func.isRequired,
  handleMediaUploadFile: PropTypes.func.isRequired,
  handleNoFileSnackClose: PropTypes.func.isRequired,
  handleUploadMediaChange: PropTypes.func.isRequired,
  handleUploadMediaClose: PropTypes.func.isRequired,
  handleUploadMediaCloseAndSave: PropTypes.func.isRequired,
  mediaData: PropTypes.object,
  noFileSnackOpen: PropTypes.bool,
  uploadMediaOpen: PropTypes.bool,
  handleMediaEditDialogClearFile: PropTypes.func.isRequired,
  handleMediaUploadDialogClearFile: PropTypes.func.isRequired,
};

export default MediaForm;
