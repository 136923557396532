import Logo from '../Logo/Logo';
import {Auth} from './Auth/Auth';
import {styled} from "@mui/system";

export const Header = ({logoType, onLandingPage = false}) => {

  // STYLED COMPONENTS BLOCK START
  const HeaderWrapper = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: `${theme.spacing(3)} 0 ${theme.spacing(5)} 0`,
    backgroundColor: theme.palette.tertiary.main,
    [theme.breakpoints.up('md')]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& div": {
      display: "flex",
      justifyContent: "flex-end",
    }
  }));
  // STYLED COMPONENTS BLOCK END

  return (
    <>
      <HeaderWrapper>
        <Logo {...{logoType}} />
        <Auth {...{onLandingPage}} />
      </HeaderWrapper>
    </>
  );
};
