import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from "@mui/styles";
import UniversalButton from "../../components/Button";
import {changePassword} from "../../helpers/changePassword";
import {getConfig} from "../../config";
import {useAuth} from "../../security/auth_context";

const UserSettingsChangePasswordContainer = styled("div")(({theme}) => ({
  backgroundColor: theme.palette.grey[200],
  padding: 10,
  borderRadius: 5,
  "& form": {
    display: "flex",
    flexDirection: "column",
    "& input": {
      margin: 5,
      padding: 5,
      fontSize: 16,
      width: 250,
      border: "1px solid grey",
      borderRadius: 4,
    },
    "& p": {
      fontSize: 12,
      marginLeft: 5,
    },
    "& button": {
      width: 150,
    }
  },
  "& h4": {
    margin: 0
  },
  "& img": {
    height: 20,
    verticalAlign: "middle",
  }
}));

const UserSettingsChangePassword = ({setSnackbarOpen, setSnackbarMessage, setSnackbarVariant}) => {
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const {apiRoot} = getConfig();
  const {authentication, isAuthenticated, getTokenSilently} = useAuth();

  const handleChangeOldPassword = (e) => {
    setOldPassword(e.target.value)
  }

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }

  const handleChangePasswordSubmit = async (e) => {
    e.preventDefault()
    const lowerCaseLetters = /[a-z]/g;
    const upperCaseLetters = /[A-Z]/g;
    const numbers = /[0-9]/g;
    if (!oldPassword) {
      alert("Vyplňte staré heslo!")
      return
    } else if (!newPassword) {
      alert("Vyplňte nové heslo!")
      return
    } else if (!confirmPassword) {
      alert("Potvrďte nové heslo!")
      return
    } else if (newPassword !== confirmPassword) {
      alert("Nové heslo se neshoduje s potvrzovacím políčkem!")
      return
    } else if (!(newPassword.match(lowerCaseLetters) && newPassword.match(upperCaseLetters) && newPassword.match(numbers) && newPassword.length >= 8)) {
      const lowerCaseMessage = "Nové heslo neobsahuje malé písmeno.";
      const upperCaseMessage = "Nové heslo neobsahuje velké písmeno.";
      const numberMessage = "Nové heslo neobsahuje číslici.";
      const lengthMessage = "Nové heslo není alespoň 8 znaků dlouhé.";
      const alertMessage = `${newPassword.match(lowerCaseLetters) ? "" : lowerCaseMessage + "\n"}${newPassword.match(upperCaseLetters) ? "" : upperCaseMessage + "\n"}${newPassword.match(numbers) ? "" : numberMessage + "\n"}${newPassword.length >= 8 ? "" : lengthMessage}`;
      alert(alertMessage)
      return
    }
    const changePasswordResponse = await changePassword(oldPassword, newPassword, apiRoot, authentication, getTokenSilently, isAuthenticated)

    if (changePasswordResponse.ResponseCode === 1) {
      setSnackbarVariant("success");
      setSnackbarMessage("Změna proběhla v pořádku.");
      setSnackbarOpen(true);
      document.getElementById("oldPass").value = ''
      document.getElementById("newPass").value = ''
      document.getElementById("confirmPass").value = ''
    } else if (changePasswordResponse.ResponseCode === 209) {
      setSnackbarVariant("error");
      setSnackbarMessage("Nesprávně zadané staré heslo.");
      setSnackbarOpen(true);
    } else if (changePasswordResponse.ResponseCode === 900) {
      setSnackbarVariant("error");
      setSnackbarMessage("Něco se pokazilo. Zkuste to prosím znovu.");
      setSnackbarOpen(true);
    }
  }

  return (
    <UserSettingsChangePasswordContainer>
      <h4>
        Změna hesla
      </h4>
      <form onSubmit={handleChangePasswordSubmit}>
        <input type="password" id={"oldPass"} name="oldPass" placeholder="Staré heslo"
               onChange={handleChangeOldPassword}/>
        <input type="password" id={"newPass"} name="newPass" placeholder="Nové heslo"
               onChange={handleChangeNewPassword}/>
        <div>
          <input type="password" id={"confirmPass"} name="confirmPass" placeholder="Potvrďte nové heslo"
                 onChange={handleChangeConfirmPassword}/>
          {confirmPassword && ((newPassword === confirmPassword)
            ? (
              // filters calculated via https://codepen.io/sosuke/pen/Pjoqqp
              <img src={"/img/icon/check.svg"} alt={"Heslo se shoduje"}
                   style={{filter: "invert(23%) sepia(99%) saturate(7376%) hue-rotate(121deg) brightness(102%) contrast(104%)"}}/>
            )
            : (
              <img src={"/img/icon/cross.svg"} alt={"Heslo se neshoduje"}
                   style={{filter: "invert(22%) sepia(98%) saturate(7498%) hue-rotate(359deg) brightness(110%) contrast(118%)"}}/>
            ))}
        </div>
        <p>
          Nové heslo musí být alespoň 8 znaků dlouhé a musí obsahovat alespoň jedno malé písmeno, jedno velké písmeno a
          jednu číslici.
        </p>
        <UniversalButton type={"submit"} variant={"contained"}>Změnit heslo</UniversalButton>
      </form>
    </UserSettingsChangePasswordContainer>
  );
};

UserSettingsChangePassword.propTypes = {
  setSnackbarOpen: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
  setSnackbarVariant: PropTypes.func.isRequired,
};

export default UserSettingsChangePassword;
